<template>
  <main class="mybutton">
    <div class="heading">
      <div class="mybutton_heading heading_wrap">
        <h3>마이버튼</h3>
        <p>
          나의 정보를 업데이트해 주세요. 최신 정보일수록 프로젝트 매칭율이
          높아집니다.
        </p>
        <p>(*은 필수입력사항 입니다.)</p>
      </div>
    </div>
    <form class="mybutton_form">
      <ul class="user_detail">
        <li class="user_id">
          아이디
          <span class="mybutton_default">{{ this.memberId }}</span>
        </li>
        <li class="user_password">
          <ul>
            <li>
              <label for="password"
                >비밀번호<span class="required">*</span></label
              >
              <p>
                <ValidationProvider
                  name="비밀번호"
                  rules="min:8|max:20|password|required"
                  ref="refPassword"
                  vid="password"
                >
                  <input
                    type="text"
                    v-model="memberPw"
                    id="password"
                    placeholder="영문, 숫자 조합 8자 이상 20자 이하"
                  />
                </ValidationProvider>
              </p>
            </li>

            <li>
              <label for="password_confirm"
                >비밀번호 확인<span class="required">*</span></label
              >
              <p>
                <ValidationProvider
                  name="비밀번호 확인"
                  rules="confirmed:password|required"
                  ref="refPasswordConfirm"
                >
                  <input
                    type="password"
                    v-model="confirmPassword"
                    id="confirmPassword"
                  />
                </ValidationProvider>
              </p>
            </li>
          </ul>
        </li>
        <li class="user_profile">
          <ul>
            <li>
              구분 <span class="user_field">프리랜서(개인)</span>
              <p class="member_name">
                <label>이름</label>
                <span class="mybutton_default">{{ this.memberNm }}</span>
              </p>
            </li>
            <li class="user_img">
              사진<span class="required">*</span>
              <p>
                <label for="profile-img" class="input_file_button uimg"
                  >파일찾기</label
                >
                <input
                  id="profile-img"
                  type="file"
                  accept=".png, .jpg"
                  @change="onChangeImages"
                  ref="selectPhoto"
                />
                <ValidationProvider name="사진" rules="required" ref="refImage">
                  <input type="text" v-model="profile_name" />
                </ValidationProvider>
                <img v-if="imageUrl" :src="imageUrl" />
              </p>
            </li>
          </ul>
        </li>
        <li>
          <ul class="user_phone_email">
            <li class="user_phone">
              <label for="mobile">휴대폰<span class="required">*</span></label>
              <p>
                <input
                  type="number"
                  id="mobile"
                  v-model="mobile"
                  placeholder=" “-”를 제외한 휴대폰 번호를 입력해 주세요"
                  ref="refMobileInput"
                />
              </p>
            </li>
            <li class="user_email">
              <label for="email_id"
                >이메일<span class="required">*</span></label
              >
              <p class="user_email_input">
                <input
                  type="text"
                  id="email_id"
                  v-model="email_head"
                  ref="refEmailInput"
                />
                @
                <input
                  v-if="this.email_selected != 'direct'"
                  type="text"
                  id="email_adress"
                  :value="email_selected"
                  disabled
                />
                <input
                  v-if="this.email_selected == 'direct'"
                  type="text"
                  id="drect_email"
                  v-model="direct_email"
                />
                <select
                  name="email_potal"
                  id="email_potal"
                  v-model="email_selected"
                >
                  <option value="" disabled selected>메일 선택</option>
                  <option value="gmail.com">gmail.com</option>
                  <option value="naver.com">naver.com</option>
                  <option value="daum.net">daum.net</option>
                  <option value="kakao.com">kakao.com</option>
                  <option value="hotmail.com">hotmail.com</option>
                  <option value="direct">직접입력</option>
                </select>
                <ValidationProvider
                  name="이메일"
                  rules="required|email"
                  ref="refEmail"
                >
                  <input class="hidden" type="email" v-model="email" />
                </ValidationProvider>
                <button type="button" @click="checkEmail">중복 확인</button>
              </p>
            </li>
          </ul>
        </li>
        <li class="user_adress">
          <label for="adress">주소</label>
          <p class="zipcode">
            <button type="button" @click="showApi">우편번호</button>
            <ValidationProvider
              name="우편번호"
              rules="required|numeric"
              ref="refZipcode"
            >
              <input
                type="text"
                id="zipcode"
                v-bind:value="zipcode"
                class="zipcode_input"
              />
            </ValidationProvider>
            <ValidationProvider name="주소" rules="required" ref="refAddress">
              <input
                type="text"
                id="adress"
                v-bind:value="addr"
                class="adress_input"
              />
            </ValidationProvider>
          </p>
        </li>
        <li>
          <ul class="user_account">
            <li>
              <label for="account"
                >계좌 관리<span class="required">*</span></label
              >
              <p>
                <select name="bank" id="bank" v-model="bankCd">
                  <option value="" disabled selected>은행선택</option>
                  <option v-for="bank in banks" :key="bank.id" :value="bank.cd">
                    {{ bank.nm }}
                  </option>
                </select>
                <ValidationProvider name="은행" rules="required" ref="refBank">
                  <input class="hidden" type="text" :value="this.bankCd" />
                </ValidationProvider>
                <ValidationProvider
                  name="계좌번호"
                  rules="required|numeric"
                  ref="refAccount"
                >
                  <input
                    type="number"
                    id="accountNumber"
                    v-model="accountNumber"
                  />
                </ValidationProvider>
              </p>
            </li>
            <li>
              통장사본<span class="required">*</span>
              <p>
                <label for="selectAccount" class="input_file_button"
                  >파일 찾기</label
                >
                <input
                  type="file"
                  id="selectAccount"
                  accept=".png, .jpg"
                  @change="onChangeAccount"
                  ref="selectAccount"
                />
                <ValidationProvider
                  name="통장사본"
                  rules="required"
                  ref="refAccImg"
                >
                  <input
                    type="text"
                    v-model="account_img"
                    class="account_img"
                  />
                </ValidationProvider>
              </p>
            </li>
          </ul>
        </li>
      </ul>
      <ul class="user_career">
        <li>
          <ul class="user_career_input">
            <li class="carrer_input">
              <label for="career_year"
                >경력 년수<span class="required">*</span></label
              >
              <p>
                <ValidationProvider
                  name="경력(년)"
                  rules="required|numeric"
                  ref="refCareerYear"
                >
                  <input
                    type="number"
                    id="career_year"
                    v-model="career_year"
                    ref="refCareerYearInput"
                  />
                </ValidationProvider>
                년
                <ValidationProvider
                  name="경력(월)"
                  rules="required|numeric"
                  ref="refCareerMonth"
                >
                  <input
                    type="number"
                    id="career_month"
                    v-model="career_month"
                    ref="refCareerMonthInput"
                  />
                </ValidationProvider>
                개월
              </p>
            </li>
            <li class="price_input">
              <label for="price"
                >희망 월 단가<span class="required">*</span></label
              >
              <p>
                <ValidationProvider
                  name="희망 월 단가"
                  rules="required|numeric"
                  ref="refPriceMin"
                >
                  <input
                    type="number"
                    id="price_min"
                    v-model="price_min"
                    ref="refPriceMinInput"
                  />
                </ValidationProvider>
                원 ~
                <ValidationProvider
                  name="희망 월 단가"
                  rules="required|numeric"
                  ref="refPriceMax"
                >
                  <input
                    type="number"
                    id="price_max"
                    v-model="price_max"
                    ref="refPriceMaxInput"
                  />
                </ValidationProvider>
                원
              </p>
            </li>
            <li>
              <label for="kosa"
                >KOSA 발급증 보유여부<span class="required">*</span></label
              >
              <p class="margin_top">
                <label class="radio_container" for="kosa_yes">
                  유
                  <input
                    type="radio"
                    id="kosa_yes"
                    name="radio"
                    value="Y"
                    v-model="kosaYn"
                    checked
                  />
                  <span class="radio_checkmark"></span>
                </label>
                <label class="radio_container" for="kosa_no">
                  무
                  <input
                    type="radio"
                    id="kosa_no"
                    name="radio"
                    value="N"
                    v-model="kosaYn"
                  />
                  <span class="radio_checkmark"></span>
                </label>
              </p>
            </li>
          </ul>
        </li>
        <li>
          <ul class="part">
            <p>파트 선택</p>
            <li>
              <p class="part_title">PM / 기획</p>
              <div class="part_container">
                <label
                  class="part_checkbox"
                  v-for="(workDetailPm, index) in workDetail.pmPlan.cds"
                  :key="index"
                >
                  <input
                    type="checkbox"
                    :value="workDetailPm.cd"
                    v-model="workDetail.pmPlan.selected"
                  />
                  <span class="part_checkmark">{{ workDetailPm.nm }}</span>
                </label>
                <p class="direct_input">
                  <label class="container">
                    <input
                      v-show="
                        workDetail.pmPlan.selected.includes(
                          'WORK_DETAIL_PM_PLAN_TXT'
                        )
                      "
                      v-model="workDetail.pmPlan.txt"
                      type="text"
                      placeholder=",로 구분"
                    />
                  </label>
                </p>
              </div>
            </li>
            <li>
              <p class="part_title">디자인</p>
              <div class="part_container">
                <label
                  class="part_checkbox"
                  v-for="(workDetailDesign, index) in workDetail.design.cds"
                  :key="index"
                >
                  <input
                    type="checkbox"
                    :value="workDetailDesign.cd"
                    v-model="workDetail.design.selected"
                  />
                  <span class="part_checkmark">{{ workDetailDesign.nm }}</span>
                </label>
                <p class="direct_input">
                  <label class="container">
                    <input
                      v-show="
                        workDetail.design.selected.includes(
                          'WORK_DETAIL_DESIGN_TXT'
                        )
                      "
                      v-model="workDetail.design.txt"
                      type="text"
                      placeholder=",로 구분"
                    />
                  </label>
                </p>
              </div>
            </li>
            <li>
              <p class="part_title">퍼블리싱</p>
              <div class="part_container">
                <label
                  class="part_checkbox"
                  v-for="(workDetailPublish, index) in workDetail.publish.cds"
                  :key="index"
                >
                  <input
                    type="checkbox"
                    :value="workDetailPublish.cd"
                    v-model="workDetail.publish.selected"
                  />
                  <span class="part_checkmark">{{ workDetailPublish.nm }}</span>
                </label>
                <p class="direct_input">
                  <label class="container">
                    <input
                      v-show="
                        workDetail.publish.selected.includes(
                          'WORK_DETAIL_PUBLISH_TXT'
                        )
                      "
                      v-model="workDetail.publish.txt"
                      type="text"
                      placeholder=",로 구분"
                    />
                  </label>
                </p>
              </div>
            </li>
            <li>
              <p class="part_title">개발</p>
              <div class="part_container">
                <label
                  class="part_checkbox"
                  v-for="(workDetailDevelop, index) in workDetail.develop.cds"
                  :key="index"
                >
                  <input
                    type="checkbox"
                    :value="workDetailDevelop.cd"
                    v-model="workDetail.develop.selected"
                  />
                  <span class="part_checkmark">{{ workDetailDevelop.nm }}</span>
                </label>
                <p class="direct_input">
                  <label class="container">
                    <input
                      v-show="
                        workDetail.develop.selected.includes(
                          'WORK_DETAIL_DEVELOP_TXT'
                        )
                      "
                      v-model="workDetail.develop.txt"
                      type="text"
                      placeholder=",로 구분"
                    />
                  </label>
                </p>
              </div>
            </li>
          </ul>
        </li>
        <li>
          <ul class="business_type">
            <li>
              <p>희망 업무형태<span class="required">*</span></p>

              <label class="part_checkbox" for="residentYn">
                <input
                  type="checkbox"
                  id="residentYn"
                  value="Y"
                  v-model="residentYn"
                />
                <span class="part_checkmark">상주(파견)</span>
              </label>
              <label class="part_checkbox" for="nonresidentYn">
                <input
                  type="checkbox"
                  id="nonresidentYn"
                  value="Y"
                  v-model="nonresidentYn"
                />
                <span class="part_checkmark">재택</span>
              </label>
              <label class="part_checkbox" for="regularYn">
                <input
                  type="checkbox"
                  id="regularYn"
                  value="Y"
                  v-model="regularYn"
                />
                <span class="part_checkmark">정규직</span>
              </label>
              <label class="part_checkbox" for="irrelevantYn">
                <input
                  type="checkbox"
                  id="irrelevantYn"
                  value="Y"
                  v-model="irrelevantYn"
                />
                <span class="part_checkmark">관계없음</span>
              </label>
            </li>
            <li>
              <p>업무 희망지역<span class="required">*</span></p>
              <div class="select_box select_w_460">
                <select name="city" id="city" v-model="areaCd">
                  <option value="" disabled selected>
                    업무 희망 지역을 선택하세요
                  </option>
                  <option :value="area.cd" v-for="area in areas" :key="area.id">
                    {{ area.nm }}
                  </option>
                </select>
              </div>
            </li>
          </ul>
        </li>
        <li>
          <ul class="career_state margin_top_60">
            <li>
              경력 기술서<span class="required">*</span>
              <p>
                <label for="career_state" class="input_file_button">
                  파일 찾기
                </label>
                <input
                  type="file"
                  id="career_state"
                  accept=".xls*, .ppt*, .doc*, .png, .jpg .pdf"
                  @change="onChangeCareerDes"
                  ref="selectCareer"
                />
                <ValidationProvider
                  name="경력 기술서"
                  rules="required"
                  ref="refCareerDes"
                >
                  <input
                    type="text"
                    v-model="resume"
                    class="career_description"
                  />
                </ValidationProvider>
              </p>
              <span class="subscription">
                (첨부파일은 20MB이하 pdf, jpg, png 형식의 파일로 등록해 주세요.)
              </span>
            </li>
            <li>
              포트폴리오 사이트
              <p>
                <input type="text" value="http://" v-model="portfolioLink" />
              </p>
            </li>
          </ul>
        </li>
        <li class="career_state_text margin_top_60">
          소개글
          <p>
            <textarea
              name="introduction"
              id="introduction"
              v-model="introduction"
              rows="10"
            ></textarea>
          </p>
        </li>
        <li class="hashtag margin_top_60">
          대표 해시태그<span class="required">*</span>
          <ValidationProvider
            name="대표 해시태그"
            rules="required"
            ref="refHashtag"
          >
            <input
              type="text"
              placeholder="제안서, 디자인, 모바일UI"
              v-model="hashtag"
            />
          </ValidationProvider>
          <div class="hashtag_list">
            <template v-for="hashtag in splitMessage">
              <p :key="hashtag.id" v-if="hashtag != ''" class="hashtagP">
                {{ "#" + hashtag.trim() }}
              </p>
            </template>
          </div>
        </li>
      </ul>
    </form>
    <div class="btn">
      <button type="button" @click="formSubmit">수정</button>
    </div>
  </main>
</template>

<script>
import client from "api-client";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      memberId: "",
      memberPw: "",
      confirmPassword: "",
      memberNm: "",
      mobile: "",
      email: "",
      email_head: "",
      email_selected: "",
      direct_email: "",
      zipcode: "",
      addr: "",
      bankCd: "",
      accountNumber: "",
      account_img: "",
      career_year: "",
      career_month: "",
      price_min: "",
      price_max: "",
      kosaYn: "",
      residentYn: "",
      nonresidentYn: "",
      regularYn: "",
      irrelevantYn: "",
      areaCd: "",
      portfolioLink: "",
      resume: "",
      introduction: "",
      hashtag: "",
      banks: [],
      areas: [],
      imageUrl: "",
      profile_name: "",
      attachCdPhoto: "",
      attachCdBankbook: "",
      attachCdResume: "",
      responseEmail: "",
      files: [],
      item: "",
      workDetail: {
        pmPlan: {
          cds: "",
          selected: [],
          txt: null,
        },
        design: {
          cds: "",
          selected: [],
          txt: null,
        },
        publish: {
          cds: "",
          selected: [],
          txt: null,
        },
        develop: {
          cds: "",
          selected: [],
          txt: null,
        },
      },
      waitRequest: true,
    };
  },
  created() {
    this.read();
  },
  computed: {
    ...mapGetters(["isAuthenticated", "getAccessToken", "getMember"]),
    splitMessage: function () {
      return this.hashtag.split(",");
    },
  },
  methods: {
    async formSubmit() {
      let validation = await this.checkAll();
      if (validation) {
        await this.update();
        await this.$router.go();
      }
    },
    async update() {
      let form = new FormData();
      form.append("attach", this.attachCdPhoto);
      form.append("attach", this.attachCdBankbook);
      form.append("attach", this.attachCdResume);

      await client.attachUpload(form).then(
        (response) => {
          const { data } = response;
          for (let i = 0; i < data.length; i++) {
            this.files[i] = data[i].attachCd;
          }
          this.attachCdPhoto = this.files[0];
          this.attachCdBankbook = this.files[1];
          this.attachCdResume = this.files[2];
        },
        (error) => {
          console.log(error);
        }
      );

      const workDetails = [];
      for (const category in this.workDetail) {
        this.workDetail[category].selected.forEach((code) => {
          let param = {
            memberId: this.getMember,
            workDetailCd: code,
            workDetailTxt:
              code == "WORK_DETAIL_PM_PLAN_TXT" ||
              code == "WORK_DETAIL_DESIGN_TXT" ||
              code == "WORK_DETAIL_PUBLISH_TXT" ||
              code == "WORK_DETAIL_DEVELOP_TXT"
                ? this.workDetail[category].txt
                : null,
          };
          workDetails.push(param);
        });
      }

      await client.postMemberUserWorkDetail(workDetails).then((error) => {
        console.log(error);
      });

      this.getEmail();
      this.residentYn == true
        ? (this.residentYn = "Y")
        : (this.residentYn = "");
      this.nonresidentYn == true
        ? (this.nonresidentYn = "Y")
        : (this.nonresidentYn = "");
      this.regularYn == true ? (this.regularYn = "Y") : (this.regularYn = "");
      this.irrelevantYn == true
        ? (this.irrelevantYn = "Y")
        : (this.irrelevantYn = "");
      const params = {
        memberId: this.memberId,
        memberPw: this.memberPw,
        memberNm: this.memberNm,
        attachCdPhoto: this.attachCdPhoto,
        mobile: this.mobile,
        email: this.email,
        zipcode: this.zipcode,
        address: this.addr,
        bankCd: this.bankCd,
        accountNumber: this.accountNumber,
        attachCdBankbook: this.attachCdBankbook,
        careerYear: this.career_year,
        careerMonth: this.career_month,
        minPrice: this.price_min,
        maxPrice: this.price_max,
        kosaYn: this.kosaYn,
        residentYn: this.residentYn,
        nonresidentYn: this.nonresidentYn,
        regularYn: this.regularYn,
        irrelevantYn: this.irrelevantYn,
        areaCd: this.areaCd,
        attachCdResume: this.attachCdResume,
        portfolioLink: this.portfolioLink,
        introduction: this.introduction,
        hashtag: this.hashtag,
      };
      this.waitRequest = true;

      await client.memberUserMod(this.getMember, params).then(
        () => {
          this.waitRequest = false;
          alert("수정 완료했습니다");
        },
        (error) => {
          this.waitRequest = false;
          console.log(error);
        }
      );
    },

    async checkAll() {
      const refPassword = await this.$refs.refPassword.validate(),
        refPasswordConfirm = await this.$refs.refPasswordConfirm.validate(),
        refImage = await this.$refs.refImage.validate(),
        refEmail = await this.$refs.refEmail.validate(),
        refZipcode = await this.$refs.refZipcode.validate(),
        refAddress = await this.$refs.refAddress.validate(),
        refBank = await this.$refs.refBank.validate(),
        refAccount = await this.$refs.refAccount.validate(),
        refAccImg = await this.$refs.refAccImg.validate(),
        refCareerYear = await this.$refs.refCareerYear.validate(),
        refCareerMonth = await this.$refs.refCareerMonth.validate(),
        refPriceMin = await this.$refs.refPriceMin.validate(),
        refPriceMax = await this.$refs.refPriceMax.validate(),
        refCareerDes = await this.$refs.refCareerDes.validate(),
        refHashtag = await this.$refs.refHashtag.validate();

      if (!refPassword.valid) {
        alert(refPassword.errors[0]);
        document.getElementById("password").focus();
        return false;
      }
      if (!refPasswordConfirm.valid) {
        alert(refPasswordConfirm.errors[0]);
        document.getElementById("confirmPassword").focus();
        return false;
      }

      if (!refImage.valid) {
        alert(refImage.errors[0]);
        return false;
      }

      if (!/^01(?:0|1|[6-9])([0-9]{3,4})([0-9]{4})$/.test(this.mobile)) {
        alert("휴대폰번호를 정확히 입력해 주세요.");
        this.$refs.refMobileInput.focus();
        return false;
      }

      if (!refEmail.valid) {
        alert(refEmail.errors[0]);
        this.$refs.refEmailInput.focus();
        return false;
      }

      if (!refZipcode.valid) {
        alert(refZipcode.errors[0]);
        this.$refs.refZipcodeInput.focus();
        return false;
      }

      if (!refAddress.valid) {
        alert(refAddress.errors[0]);
        this.$refs.refAddressInput.focus();
        return false;
      }

      if (!refBank.valid) {
        alert(refBank.errors[0]);
        return false;
      }

      if (!refAccount.valid) {
        alert(refAccount.errors[0]);
        this.$refs.refAccountInput.focus();
        return false;
      }

      if (!refAccImg.valid) {
        alert(refAccImg.errors[0]);
        return false;
      }

      if (!refCareerYear.valid) {
        alert(refCareerYear.errors[0]);
        this.$refs.refCareerYearInput.focus();
        return false;
      }

      if (!refCareerMonth.valid) {
        alert(refCareerMonth.errors[0]);
        this.$refs.refCareerMonthInput.focus();
        return false;
      }

      if (!refPriceMin.valid) {
        alert(refPriceMin.errors[0]);
        this.$refs.refPriceMinInput.focus();
        return false;
      }

      if (!refPriceMax.valid) {
        alert(refPriceMax.errors[0]);
        this.$refs.refPriceMaxInput.focus();
        return false;
      }

      if (
        this.residentYn == "" &&
        this.nonresidentYn == "" &&
        this.regularYn == "" &&
        this.irrelevantYn == ""
      ) {
        alert("희망 업무형태를 입력해 주세요.");
        return false;
      }

      if (this.areaCd == "") {
        alert("희망 업무지역을 입력해 주세요.");
        return false;
      }

      if (!refCareerDes.valid) {
        alert(refCareerDes.errors[0]);
        return false;
      }

      if (!refHashtag.valid) {
        alert(refHashtag.errors[0]);
        return false;
      }

      return true;
    },
    read() {
      client.memberUserView(this.getMember).then(
        (response) => {
          const { data } = response;
          this.item = data;
          this.memberId = data.memberId;
          this.memberNm = data.memberNm;
          this.imageUrl =
            process.env.VUE_APP_API_URL + "/api/attach/" + data.attachCdPhoto;
          this.attachCdPhoto = data.attachCdPhoto;
          this.attachCdBankbook = data.attachCdBankbook;
          this.attachCdResume = data.attachCdResume;
          this.profile_name = data.attachPhoto.fileNm;
          this.mobile = data.mobile;
          this.email = data.email;
          this.responseEmail = this.email.split("@");
          this.email_head = this.responseEmail[0];
          this.email_selected = this.responseEmail[1];
          if (this.responseEmail[1] != this.email_selected) {
            this.direct_email = this.responseEmail[1];
          }
          this.zipcode = data.zipcode;
          this.addr = data.address;
          this.bankCd = data.bankCd;
          this.accountNumber = data.accountNumber;
          this.account_img = data.attachBankbook.fileNm;
          this.career_year = data.careerYear;
          this.career_month = data.careerMonth;
          this.price_min = data.minPrice;
          this.price_max = data.maxPrice;
          this.kosaYn = data.kosaYn;
          this.residentYn = data.residentYn;
          this.nonresidentYn = data.nonresidentYn;
          this.regularYn = data.regularYn;
          this.irrelevantYn = data.irrelevantYn;
          this.residentYn == "Y"
            ? (this.residentYn = true)
            : (this.residentYn = false);
          this.nonresidentYn == "Y"
            ? (this.nonresidentYn = true)
            : (this.nonresidentYn = false);
          this.regularYn == "Y"
            ? (this.regularYn = true)
            : (this.regularYn = false);
          this.irrelevantYn == "Y"
            ? (this.irrelevantYn = true)
            : (this.irrelevantYn = false);
          this.areaCd = data.areaCd;
          this.resume = data.attachResume.fileNm;
          this.portfolioLink = data.portfolioLink;
          this.introduction = data.introduction;
          this.hashtag = data.hashtag;
        },
        (error) => {
          console.log(error);
        }
      );

      let param = {
        memberId: this.getMember,
      };
      client.getMemberUserWorkDetail(param).then(
        (response) => {
          const { data } = response;
          data.forEach((item) => {
            if (item.codeEntity.upCd == "WORK_DETAIL_PM_PLAN") {
              this.workDetail.pmPlan.selected.push(item.workDetailCd);
            } else if (item.codeEntity.upCd == "WORK_DETAIL_DESIGN") {
              this.workDetail.design.selected.push(item.workDetailCd);
            } else if (item.codeEntity.upCd == "WORK_DETAIL_PUBLISH") {
              this.workDetail.publish.selected.push(item.workDetailCd);
            } else if (item.codeEntity.upCd == "WORK_DETAIL_DEVELOP") {
              this.workDetail.develop.selected.push(item.workDetailCd);
            }

            if (item.workDetailCd == "WORK_DETAIL_PM_PLAN_TXT") {
              this.workDetail.pmPlan.txt = item.workDetailTxt;
            } else if (item.workDetailCd == "WORK_DETAIL_DESIGN_TXT") {
              this.workDetail.design.txt = item.workDetailTxt;
            } else if (item.workDetailCd == "WORK_DETAIL_PUBLISH_TXT") {
              this.workDetail.publish.txt = item.workDetailTxt;
            } else if (item.workDetailCd == "WORK_DETAIL_DEVELOP_TXT") {
              this.workDetail.develop.txt = item.workDetailTxt;
            }
          });
        },
        (error) => {
          console.log(error);
        }
      );

      client.codeList("BANK").then(
        (response) => {
          const { data } = response;
          this.banks = data;
        },
        (error) => {
          console.log(error);
        }
      );
      client.codeList("AREA").then(
        (response) => {
          const { data } = response;
          this.areas = data;
        },
        (error) => {
          console.log(error);
        }
      );
      client.codeList("WORK_DETAIL_PM_PLAN").then(
        (response) => {
          const { data } = response;
          this.workDetail.pmPlan.cds = data;
        },
        (error) => {
          console.log(error);
        }
      );
      client.codeList("WORK_DETAIL_DESIGN").then(
        (response) => {
          const { data } = response;
          this.workDetail.design.cds = data;
        },
        (error) => {
          console.log(error);
        }
      );
      client.codeList("WORK_DETAIL_PUBLISH").then(
        (response) => {
          const { data } = response;
          this.workDetail.publish.cds = data;
        },
        (error) => {
          console.log(error);
        }
      );
      client.codeList("WORK_DETAIL_DEVELOP").then(
        (response) => {
          const { data } = response;
          this.workDetail.develop.cds = data;
        },
        (error) => {
          console.log(error);
        }
      );
    },
    checkEmail() {
      this.getEmail();
      let params = new URLSearchParams({
        email: this.email,
      }).toString();
      client.memberEmail(params).then(
        (response) => {
          const { data } = response;
          if (data.totalCount != 0 || data.totalCount > 0) {
            alert("이미 사용중인 이메일 입니다.");
          } else {
            alert("사용가능한 이메일 입니다.");
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    getEmail() {
      if (this.email_selected != "direct") {
        this.email = [this.email_head, this.email_selected];
        this.email = this.email.join("@");
      }
      if (this.email_selected == "direct") {
        this.email = [this.email_head, this.direct_email];
        this.email = this.email.join("@");
      }
    },
    onChangeImages(e) {
      if (0 < this.$refs.selectPhoto.files.length) {
        this.attachCdPhoto = this.$refs.selectPhoto.files[0];
        let fileExt = this.attachCdPhoto.name.substring(
          this.attachCdPhoto.name.lastIndexOf(".") + 1
        );
        fileExt = fileExt.toLowerCase();
        if (["jpeg", "jpg", "png", "gif", "bmp"].includes(fileExt)) {
          const file = e.target.files[0];
          this.imageUrl = URL.createObjectURL(file);
          this.profile_name = file.name;
        } else {
          alert("파일을 다시 선택해 주세요.");
          this.attachCdPhoto = null;
        }
      }
    },
    onChangeAccount(e) {
      if (0 < this.$refs.selectAccount.files.length) {
        this.attachCdBankbook = this.$refs.selectAccount.files[0];
        let fileExt = this.attachCdBankbook.name.substring(
          this.attachCdBankbook.name.lastIndexOf(".") + 1
        );
        fileExt = fileExt.toLowerCase();
        if (["jpeg", "jpg", "png", "gif", "bmp"].includes(fileExt)) {
          const accountImg = e.target.files[0];
          this.account_img = accountImg.name;
        } else {
          alert("파일을 다시 선택해 주세요.");
          this.attachCdBankbook = null;
        }
      }
    },
    onChangeCareerDes(e) {
      if (0 < this.$refs.selectCareer.files.length) {
        this.attachCdResume = this.$refs.selectCareer.files[0];
        let fileExt = this.attachCdResume.name.substring(
          this.attachCdResume.name.lastIndexOf(".") + 1
        );
        fileExt = fileExt.toLowerCase();
        if (
          [
            "zip",
            "gz",
            "tgz",
            "hwp",
            "txt",
            "doc",
            "docx",
            "pdf",
            "ppt",
            "pptx",
            "xls",
            "xlsx",
            "jpeg",
            "jpg",
            "png",
            "gif",
            "bmp",
          ].includes(fileExt)
        ) {
          const careerDes = e.target.files[0];
          this.resume = careerDes.name;
        } else {
          alert("파일을 다시 선택해 주세요.");
          this.attachCdResume = null;
        }
      }
    },
    showApi() {
      new window.daum.Postcode({
        oncomplete: (data) => {
          let addr = data.roadAdress;
          let extraAddr = "";
          if (data.userSelectedType === "R") {
            addr = data.roadAddress;
          } else {
            addr = data.jibunAddress;
          }
          if (data.userSelectedType === "R") {
            if (data.bname !== "" && /[동|로|가]$/g.test(data.bname)) {
              extraAddr += data.bname;
            }
            if (data.buildingName !== "" && data.apartment === "Y") {
              extraAddr +=
                extraAddr !== "" ? ", " + data.buildingName : data.buildingName;
            }
            if (extraAddr !== "") {
              extraAddr = " (" + extraAddr + ")";
            }
            this.addr = extraAddr;
          } else {
            this.addr = "";
          }
          this.zipcode = data.zonecode;
          this.addr = addr + extraAddr;
          document.getElementById("adress").focus();
        },
      }).open();
    },
  },
};
</script>
